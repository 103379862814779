import firebase from 'firebase/app';
import 'firebase/auth';
import config from '@/config';
import { IAuthClient } from '@/services/auth';

export default class FirebaseAuthClient implements IAuthClient {
  private auth: firebase.auth.Auth;

  constructor() {
    firebase.initializeApp(config.firebase);
    this.auth = firebase.auth();
  }

  async signIn(email: string, pass: string): Promise<void> {
    await this.auth.signInWithEmailAndPassword(email, pass);
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  async isEmailVerified(): Promise<boolean> {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      return currentUser.emailVerified;
    }
    return false;
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    await firebase.auth().sendPasswordResetEmail(email);
  }

  async sendEmailVerification(): Promise<void> {
    if (this.auth.currentUser) {
      const alreadyVerified = await this.isEmailVerified();
      if (alreadyVerified) {
        return;
      }
      await this.auth.currentUser.sendEmailVerification({
        url: config.firebase.emailVerificationURL
      });
    }
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.User | null> {
    const { user } = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    return user;
  }
}
