import Bff from '@/api/Bff';
import { SkyNodeApi } from '@/api/SkyNodeApi';
import { UserBff } from '@/api/UserBff';
import config from '@/config';
import { AuthClient } from '@/services/auth';
import FirebaseAuthClient from '@/services/auth/firebaseAuthClient';
import PlatformOneAuthClient from '@/services/auth/platformOneAuthClient';
import axios from 'axios';
import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import {
  create as createAdminModule,
  name as adminModuleName
} from './skyhub/admin';
import {
  create as createPackageModule,
  name as packageModuleName
} from './skyhub/package';
import {
  create as createSkyNodeModule,
  name as skyNodeModuleName
} from './skyhub/skynode';
import { RootState } from './types';
import { create as createUserModule, name as userModuleName } from './user';

export default function createStore(): Store<RootState> {
  const skyNodeAxiosInstance = axios.create({
    baseURL: config.skynode.skynodeApi,
    timeout: 10000
  });

  const skyNodeApi: SkyNodeApi = new SkyNodeApi(skyNodeAxiosInstance);
  const bff: Bff = new Bff(skyNodeApi);
  const userBff = new UserBff(skyNodeApi);
  const packageModule = createPackageModule(bff);
  const userModule = createUserModule(userBff);
  const skyNodeModule = createSkyNodeModule(bff);
  const adminModule = createAdminModule(bff);

  const storeOptions: StoreOptions<RootState> = {
    modules: {
      [packageModuleName]: packageModule,
      [userModuleName]: userModule,
      [skyNodeModuleName]: skyNodeModule,
      [adminModuleName]: adminModule
    }
  };

  Vue.use(Vuex);

  const store = new Vuex.Store<RootState>(storeOptions);

  const implementation = config.environment;
  let authClient;
  switch (implementation) {
    case 'firebase':
      authClient = new FirebaseAuthClient();
      break;
    case 'platform_one':
    default:
      authClient = new PlatformOneAuthClient(skyNodeApi, store);
      break;
  }
  AuthClient.initialize(authClient);

  // Add a request interceptor
  if (config.environment === 'firebase') {
    skyNodeAxiosInstance.interceptors.request.use(async function(
      configuration
    ) {
      const isLoggedIn = store.getters[`${userModuleName}/isLoggedIn`];
      if (isLoggedIn) {
        const idToken = store.getters[`${userModuleName}/firebaseIdToken`];
        configuration.headers.Authorization = `Bearer ${idToken}`;
      }
      return configuration;
    });
  }
  return store;
}
