import { SkyHubUser } from '@/models/SkyHubUser';
import ElectronAuthSender from '@/store/electron/package/ElectronAuthSender';
import { SkyNodeApi } from './SkyNodeApi';

interface IUserBff {
  getSkyHubUserById(id: string): Promise<SkyHubUser>;
  createSkyHubUser(user: SkyHubUser): Promise<SkyHubUser>;
  updateSkyHubUser(user: SkyHubUser): Promise<void>;
}

enum URIs {
  package = 'package',
  skynode = 'skynode',
  user = 'user'
}

class ElectronUserBff implements IUserBff {
  private electronMessageService: ElectronAuthSender;
  constructor(electronMessageService: ElectronAuthSender) {
    this.electronMessageService = electronMessageService;
  }

  public async getSkyHubUserById(id: string): Promise<SkyHubUser> {
    return this.electronMessageService.getSkyHubUserById(id);
  }

  public async createSkyHubUser(user: SkyHubUser): Promise<SkyHubUser> {
    return this.electronMessageService.createSkyHubUser(user);
  }

  public async updateSkyHubUser(user: SkyHubUser): Promise<void> {
    return this.electronMessageService.updateSkyHubUser(user);
  }
}

class UserBff implements IUserBff {
  private skyNodeApi: SkyNodeApi;
  constructor(skyNodeApi: SkyNodeApi) {
    this.skyNodeApi = skyNodeApi;
  }

  public async getSkyHubUserById(id: string): Promise<SkyHubUser> {
    const response = await this.skyNodeApi.get(`${URIs.user}/${id}`);
    return response.data;
  }

  public async createSkyHubUser(user: SkyHubUser): Promise<SkyHubUser> {
    const response = await this.skyNodeApi.post(URIs.user, user);
    return response.data;
  }

  public async updateSkyHubUser(user: SkyHubUser): Promise<void> {
    await this.skyNodeApi.patch(`${URIs.user}/${user.uid}`, user);
  }
}

export { ElectronUserBff, UserBff, IUserBff };
