
























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { SkyHubUser } from '../models/SkyHubUser';
import { UserRole } from '@/models/UserRoles';
import config from '@/config';

@Component({
  computed: {
    ...mapState('userModule', ['currentUser', 'isLoggedIn', 'isEmailVerified'])
  }
})
export default class NavComponent extends Vue {
  private currentUser!: SkyHubUser;
  private isLoggedIn!: boolean;
  private isEmailVerified!: boolean;
  private show = false;
  private isElectron = false;

  created() {
    this.isElectron = config.skynode.isEnabled;
  }

  @Prop({ default: 'Skyhub ' })
  private title!: string;

  get isAdmin() {
    return this.currentUser && this.currentUser.role === UserRole.ADMIN;
  }

  private hide() {
    this.show = false;
  }

  get isP1Mode() {
    return config.environment == 'platform_one';
  }
}
