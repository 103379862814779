import { IPackageVersion } from '@/models/packages/PackageVersion';
import { ISkyNodeClient } from '@/models/skynode/SkyNodeClient';
import { MutationTree } from 'vuex';
import { SkyNodeClientState } from './types';

export const mutations: MutationTree<SkyNodeClientState> = {
  SET_ALL_SKY_NODES(state, nodes: ISkyNodeClient[]): void {
    state.nodes = nodes;
  },
  SET_SKYNODE(state, node: ISkyNodeClient): void {
    state.node = node;
  },
  SET_PACKAGE_VERSIONS(state, packageVersions: IPackageVersion[]): void {
    state.packageVersions = packageVersions;
  },
  UPDATE_SKYNODE_ALLOW(state, allowUpdate: boolean): void {
    state.node.allow = allowUpdate;
  }
};
