import { UserRole } from '@/models/UserRoles';
import { RootState } from '@/store/types';
import PageContents from '@/util/pageContents';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { Store } from 'vuex';

Vue.use(VueRouter);

//INVALID - P1 code smell. revert when page params change
function pageGetters(store: Store<RootState>) {
  return {
    currentUser: store.getters['userModule/currentUser'],
    isEmailVerified: store.getters['userModule/isEmailVerified']
  };
}

//INVALID - P1 code smell. revert when page params change
function packagePageGetters(store: Store<RootState>) {
  return {
    pack: store.getters['packageModule/package']
  };
}

export function createRouter(store: Store<RootState>) {
  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/LoginPage.vue')
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('@/pages/LogoutPage.vue')
      },
      {
        path: '/home',
        name: 'home',
        props: () => {
          return pageGetters(store);
        },
        meta: {
          reload: true
        },
        component: () => import('@/pages/skyhub/HomePage.vue')
      },
      {
        path: '/signup',
        name: 'signup',
        component: () => import('@/pages/SignupPage.vue')
      },
      {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/pages/ForgotPasswordPage.vue')
      },
      {
        path: '/verification',
        name: 'verification',
        props: () => {
          return pageGetters(store);
        },
        component: () => import('@/pages/AccountVerificationPage.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          roles: [UserRole.ADMIN, UserRole.USER],
          requiresAuth: true
        },
        props: () => {
          return pageGetters(store);
        },
        component: () => import('@/pages/skyhub/UserProfilePage.vue')
      },
      {
        path: '/admin/user',
        name: 'admin.user',
        meta: {
          roles: [UserRole.ADMIN],
          requiresAuth: true
        },
        component: () => import('@/pages/skyhub/UserAdminPage.vue'),
        async beforeEnter(to, from, next) {
          await store.dispatch('adminModule/fetchAllUsers');
          next();
        },
        props: () => {
          return {
            currentUser: store.getters['userModule/currentUser'],
            users: store.getters['adminModule/users'],
            pageTitle: PageContents.TableHeaders.USER_COLLECTION
          };
        }
      },
      {
        path: '/package',
        name: 'package.collection',
        component: () => import('@/pages/skyhub/PackageCollectionPage.vue'),
        beforeEnter: async (to, from, next) => {
          await store.dispatch('packageModule/fetchAll');
          next();
        },
        meta: {
          roles: [UserRole.ADMIN, UserRole.USER],
          requiresAuth: true
        },
        props: () => {
          return {
            packs: store.getters['packageModule/packages'],
            pageTitle: PageContents.TableHeaders.PACKAGE_COLLECTION
          };
        }
      },
      {
        path: '/package/create',
        name: 'package.create',
        component: () => import('@/pages/skyhub/CreatePackagePage.vue'),
        meta: {
          roles: [UserRole.ADMIN, UserRole.USER],
          requiresAuth: true
        }
      },
      {
        path: '/package/:id/edit',
        name: 'package.detail.edit',
        async beforeEnter(to, from, next) {
          const packageId = to.params.id;
          await store.dispatch('packageModule/fetchById', packageId);
          next();
        },
        meta: {
          roles: [UserRole.ADMIN, UserRole.USER],
          requiresAuth: true
        },
        props: () => {
          return packagePageGetters(store);
        },
        component: () => import('@/pages/skyhub/EditPackagePage.vue')
      },
      {
        path: '/package/:id',
        name: 'package.detail',
        component: () => import('@/pages/skyhub/PackageDetailPage.vue'),
        async beforeEnter(to, from, next) {
          await store.dispatch('packageModule/fetchById', to.params.id);
          next();
        },
        meta: {
          roles: [UserRole.ADMIN, UserRole.USER],
          requiresAuth: true
        },
        props: () => {
          return packagePageGetters(store);
        }
      },
      {
        path: '/package/:id/version/create',
        name: 'package.detail.version.create',
        component: () => import('@/pages/skyhub/CreatePackageVersionPage.vue')
      },
      {
        path: '/skynode',
        name: 'skynode.collection',
        meta: {
          roles: [UserRole.ADMIN],
          requiresAuth: true
        },
        beforeEnter(to, from, next) {
          store.dispatch('skyNodeModule/getAllSkyNodes');
          next();
        },
        props: () => {
          return {
            nodes: store.getters['skyNodeModule/nodes'],
            currentUser: store.getters['userModule/currentUser'],
            pageTitle: PageContents.TableHeaders.SKYNODE_COLLECTION
          };
        },
        component: () => import('@/pages/skyhub/SkyNodeCollectionPage.vue')
      },
      {
        path: '/skynode/:id',
        name: 'skynode.detail',
        meta: {
          roles: [UserRole.ADMIN],
          requiresAuth: true
        },
        async beforeEnter(to, from, next) {
          await store.dispatch('skyNodeModule/fetchById', to.params.id);
          next();
        },
        props: () => {
          return {
            node: store.getters['skyNodeModule/node'],
            currentUser: store.getters['userModule/currentUser'],
            packageVersions: store.getters['skyNodeModule/packageVersions']
          };
        },
        component: () => import('@/pages/skyhub/SkyNodeDetailPage.vue')
      },
      {
        path: '*',
        redirect: { name: 'home' }
      }
    ]
  });
}
