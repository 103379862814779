import { GetterTree } from 'vuex';
import { AdminState } from './types';
import { RootState } from '../../types';
import { ISkyHubUser } from '@/models/SkyHubUser';

export const getters: GetterTree<AdminState, RootState> = {
  users(state): ISkyHubUser[] {
    return state.users;
  }
};
