enum UserRole {
    USER,
    ADMIN
}

const UserRoleLabels = new Map<number, string>([
    [UserRole.USER, 'User'],
    [UserRole.ADMIN, 'Admin'],
]);

export {UserRole, UserRoleLabels}