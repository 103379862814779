import { SkyNodeApi } from '@/api/SkyNodeApi';
import firebase from 'firebase';
import { Store } from 'vuex';
import { IAuthClient } from '.';

export default class PlatformOneAuthClient<T> implements IAuthClient {
  private api: SkyNodeApi;
  private store: Store<T>;

  constructor(api: SkyNodeApi, store: Store<T>) {
    this.api = api;
    this.store = store;
  }

  async signIn(): Promise<void> {
    const res = await this.api.get('user/ssoUser');
    await this.store.dispatch('userModule/login', res.data);
  }

  async signOut(): Promise<void> {
    return;
  }

  async isEmailVerified(): Promise<boolean> {
    return true;
  }

  async sendPasswordResetEmail(): Promise<void> {
    return;
  }

  async sendEmailVerification(): Promise<void> {
    return;
  }

  async createUserWithEmailAndPassword(): Promise<firebase.User | null> {
    return null;
  }
}
