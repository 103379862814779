import { GetterTree } from 'vuex';
import { PackageState } from './types';
import { RootState } from '../../types';
import { IPackage } from '@/models/packages/Package';
import { IPackageVersion } from '@/models/packages/PackageVersion';

export const getters: GetterTree<PackageState, RootState> = {
  packages(state): IPackage[] {
    return state.packs;
  },
  package(state): IPackage | undefined {
    return state.pack;
  },
  versions(state): IPackageVersion[] {
    return state.versions;
  },
  uploadProgressPercentage(state): number {
    return state.uploadProgressPercentage;
  }
};
