import { IUserBff } from '@/api/UserBff';
import { SkyHubUser } from '@/models/SkyHubUser';
import { AuthClient } from '@/services/auth';
import firebase from 'firebase';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { UserState } from './types';
import { MutationKeys } from './mutations';
class UserActionKeys {
  public static readonly LOGOUT = 'logout';
  public static readonly LOGIN = 'login';
  public static readonly UPDATE_SKYHUB_USER = 'updateSkyHubUser';
  public static readonly UPDATE_SKYHUB_USER_ROLE = 'updateSkyHubUserRole';
  public static readonly SET_IS_LOGGED_IN = 'setIsLoggedIn';
  public static readonly SET_IS_EMAIL_VERIFIED = 'setIsEmailVerifed';
  public static readonly SET_USER = 'setUser';
  public static readonly SET_FIREBASE_ID_TOKEN = 'setFirebaseIDToken';
}

function createActions(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bff: IUserBff
): ActionTree<UserState, RootState> {
  return {
    [UserActionKeys.LOGOUT]({ commit }) {
      commit(MutationKeys.SET_USER, null);
      commit(MutationKeys.SET_IS_LOGGED_IN, false);
      commit(MutationKeys.SET_IS_EMAIL_VERIFIED, false);
    },
    async [UserActionKeys.LOGIN]({ commit }, auth: firebase.User) {
      const authUser = SkyHubUser.fromAuthClient(auth);
      const verified = await AuthClient.getInstance().isEmailVerified();

      if (auth.getIdToken) {
        const idToken = await auth.getIdToken();
        commit(MutationKeys.SET_FIREBASE_ID_TOKEN, idToken);
      }
      commit(MutationKeys.SET_IS_LOGGED_IN, true);
      commit(MutationKeys.SET_IS_EMAIL_VERIFIED, verified);

      let user = null;
      try {
        user = await bff.getSkyHubUserById(auth.uid);
      } catch (err) {
        if (!err.message.includes('404')) {
          throw err;
        }
      }
      if (!user) {
        await bff.createSkyHubUser(authUser);
        user = authUser;
      }
      commit(MutationKeys.SET_USER, user);
    },
    async [UserActionKeys.UPDATE_SKYHUB_USER]({ commit }, user: SkyHubUser) {
      await bff.updateSkyHubUser(user);
      commit(MutationKeys.SET_USER, user);
    },
    async [UserActionKeys.UPDATE_SKYHUB_USER_ROLE](_, user: SkyHubUser) {
      await bff.updateSkyHubUser(user);
    },
    [UserActionKeys.SET_IS_LOGGED_IN]({ commit }, status: boolean) {
      commit(MutationKeys.SET_IS_LOGGED_IN, status);
    },
    [UserActionKeys.SET_IS_EMAIL_VERIFIED]({ commit }, status: boolean) {
      commit(MutationKeys.SET_IS_EMAIL_VERIFIED, status);
    },
    [UserActionKeys.SET_USER]({ commit }, user: SkyHubUser) {
      commit(MutationKeys.SET_USER, user);
    },
    [UserActionKeys.SET_FIREBASE_ID_TOKEN]({ commit }, IDToken: string) {
      commit(MutationKeys.SET_FIREBASE_ID_TOKEN, IDToken);
    }
  } as ActionTree<UserState, RootState>;
}

export { createActions, UserActionKeys };
