import { SkyHubUser } from '@/models/SkyHubUser';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { UserState } from './types';

export const getters: GetterTree<UserState, RootState> = {
  currentUser(state): SkyHubUser | null {
    return state.currentUser;
  },
  firebaseIdToken(state): string | null {
    return state.firebaseIdToken;
  },
  isLoggedIn(state): boolean {
    return state.isLoggedIn;
  },
  isEmailVerified(state): boolean {
    return state.isEmailVerified;
  }
};
