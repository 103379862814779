var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "z-0 flex items-center justify-between px-6 py-3 bg-black shadow flex-wrap-no-wrap"
    },
    [
      _c("img", {
        staticClass: "inline-block text-white",
        attrs: { src: require("../../public/skyhub_mark.svg"), alt: "" }
      }),
      _c(
        "div",
        { staticClass: "ml-4 mr-6 text-white" },
        [
          _c("router-link", { attrs: { to: "/", active: false } }, [
            _c("span", { staticClass: "text-xl tracking-tight" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex w-10/12 h-full text-white border-green-500 hover:text-white"
        },
        [
          !_vm.isElectron && _vm.isLoggedIn && _vm.isEmailVerified
            ? _c(
                "span",
                { staticClass: "whitespace-no-wrap" },
                [
                  _vm.isAdmin
                    ? _c(
                        "router-link",
                        {
                          staticClass: "mr-4",
                          attrs: { to: { name: "admin.user" } }
                        },
                        [_vm._v("Admin")]
                      )
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "mr-4",
                      attrs: { to: { name: "package.collection" } }
                    },
                    [_vm._v("Packages")]
                  ),
                  _vm.isAdmin
                    ? _c(
                        "router-link",
                        {
                          staticClass: "mr-4",
                          attrs: { to: { name: "skynode.collection" } }
                        },
                        [_vm._v("Skynodes")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("div", { staticClass: "flex items-center" }, [
        _vm.currentUser
          ? _c(
              "span",
              {
                staticClass:
                  "hidden tracking-tight text-white whitespace-nowrap text-l sm:contents"
              },
              [_vm._v(_vm._s(_vm.currentUser.displayName) + " ")]
            )
          : _vm._e(),
        _c("div", { staticClass: "inline-block text-left items-right" }, [
          _c(
            "button",
            {
              staticClass: "flex content-between focus:outline-none",
              attrs: { "data-cy": "profile-dropdown-button" },
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                },
                blur: function($event) {
                  _vm.show = false
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-8 h-8 mx-4 overflow-hidden border-2 border-white rounded-full"
                },
                [
                  _vm.currentUser &&
                  _vm.currentUser.photoUrl &&
                  _vm.currentUser.photoUrl.startsWith("http")
                    ? _c("img", {
                        attrs: {
                          src: _vm.currentUser.photoUrl,
                          alt: "Profile image"
                        }
                      })
                    : _c("font-awesome-icon", {
                        staticClass: "text-white fa-lg",
                        attrs: { icon: ["fas", "user"] }
                      })
                ],
                1
              ),
              _c("font-awesome-icon", {
                staticClass: "inline mt-2 text-white fa-lg",
                attrs: { icon: ["fas", "chevron-down"] }
              })
            ],
            1
          ),
          _vm.show
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute right-0 z-40 w-24 mt-1 origin-top-right rounded-md shadow-lg"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "py-1 bg-white rounded-md shadow-xs",
                      on: {
                        mousedown: function($event) {
                          return $event.preventDefault()
                        }
                      }
                    },
                    [
                      _vm.isLoggedIn
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: { name: "user" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.hide()
                                }
                              }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex items-center w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                },
                                [_vm._v(" Profile ")]
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.isLoggedIn && !_vm.isP1Mode
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: { name: "login" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.hide()
                                }
                              }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex items-center w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                },
                                [_vm._v(" Log in ")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isLoggedIn && !_vm.isP1Mode
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: { name: "logout" } },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.hide()
                                }
                              }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex items-center w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900",
                                  attrs: {
                                    "data-cy": "profile-dropdown-logout"
                                  }
                                },
                                [_vm._v(" Log Out ")]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }