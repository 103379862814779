interface IConfig {
  buildType: string;
  buildStage: string;
  environment: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
    emailVerificationURL: string;
    firestore: { local: boolean; host: string };
    functions: { local: boolean; host: string };
  };
  skyhub: {
    machineLearningApi: string;
  };
  skynode: {
    isEnabled: boolean;
    maxDownloads: number;
    skynodeApi: string;
    defaultSyncInterval: number;
    keyCloak: {
      loginUrl: string;
      apiUrl: string;
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function buildConfig(env: any): IConfig {
  return {
    buildStage: env.VUE_APP_STAGE,
    buildType: env.NODE_ENV,
    environment: env.VUE_APP_ENVIRONMENT,
    firebase: {
      apiKey: env.VUE_APP_FIRESTORE_API_KEY,
      authDomain: env.VUE_APP_FIRESTORE_AUTH_DOMAIN,
      databaseURL: env.VUE_APP_FIRESTORE_DATABASE_URL,
      projectId: env.VUE_APP_FIRESTORE_PROJECT_ID,
      storageBucket: env.VUE_APP_FIRESTORE_STORAGE_BUCKET,
      messagingSenderId: env.VUE_APP_FIRESTORE_MESSAGING_SENDER_ID,
      appId: env.VUE_APP_FIRESTORE_APP_ID,
      measurementId: env.VUE_APP_FIRESTORE_MEASUREMENT_ID,
      emailVerificationURL: env.VUE_APP_FIREBASE_EMAIL_VERIFICATION_URL,
      firestore: {
        local: env.VUE_APP_FIREBASE_FIRESTORE_LOCAL == 'true' ? true : false,
        host: env.VUE_APP_FIREBASE_FIRESTORE_HOST
      },
      functions: {
        local: env.VUE_APP_FIREBASE_FUNCTIONS_LOCAL == 'true' ? true : false,
        host: env.VUE_APP_FIREBASE_FUNCTIONS_HOST
      }
    },
    skyhub: {
      machineLearningApi: env.VUE_APP_SKYHUB_MACHINELEARNINGAPI
    },
    skynode: {
      isEnabled: env.IS_ELECTRON,
      maxDownloads: env.MAX_ELECTRON_DOWNLOADS || 1,
      skynodeApi: env.VUE_APP_SKYNODE_SKYNODEAPI,
      defaultSyncInterval: parseInt(
        env.SKYNODE_DEFAULT_SYNC_INTERVAL || '5000'
      ),
      keyCloak: {
        loginUrl: env.KEYCLOAK_LOGIN_URL || 'https://login.dso.mil',
        apiUrl: env.KEYCLOCK_API_URL || 'https://skyhub.staging.dso.mil'
      }
    }
  };
}

export default buildConfig(process.env);

export { IConfig, buildConfig };
