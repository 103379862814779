import { ISkyHubUser } from '@/models/SkyHubUser';
import { MutationTree } from 'vuex';
import { UserState } from './types';

class MutationKeys {
  public static readonly SET_USER = 'SET_USER';
  public static readonly SET_FIREBASE_ID_TOKEN = 'SET_FIREBASE_ID_TOKEN';
  public static readonly SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
  public static readonly SET_IS_EMAIL_VERIFIED = 'SET_IS_EMAIL_VERIFIIED';
}

const mutations: MutationTree<UserState> = {
  [MutationKeys.SET_USER](state, user: ISkyHubUser): void {
    state.currentUser = user;
  },
  [MutationKeys.SET_FIREBASE_ID_TOKEN](state, idToken: string) {
    state.firebaseIdToken = idToken;
  },
  [MutationKeys.SET_IS_LOGGED_IN](state, isLoggedIn: boolean): void {
    state.isLoggedIn = isLoggedIn;
  },
  [MutationKeys.SET_IS_EMAIL_VERIFIED](state, isEmailVerified: boolean): void {
    state.isEmailVerified = isEmailVerified;
  }
};

export { MutationKeys, mutations };
