import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { AdminState } from './types';
import { RootState } from '../../types';
import Bff from '@/api/Bff';

export const state: AdminState = {
  users: []
};

export function create(bff: Bff): Module<AdminState, RootState> {
  const actions = createActions(bff);
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  } as Module<AdminState, RootState>;
}

export const name = 'adminModule';
