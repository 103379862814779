import Bff from '@/api/Bff';
import { SkyHubUser } from '@/models/SkyHubUser';
import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { AdminState } from './types';

class AdminActionKeys {
  public static readonly UPDATE_SKYHUB_USER = 'updateSkyHubUser';
  public static readonly UPDATE_SKYHUB_USER_ROLE = 'updateSkyHubUserRole';
  public static readonly FETCH_ALL_USERS = 'fetchAllUsers';
}

function createActions(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bff: Bff
): ActionTree<AdminState, RootState> {
  return {
    async [AdminActionKeys.UPDATE_SKYHUB_USER](_, user: SkyHubUser) {
      return bff.admin.updateSkyHubUser(user);
    },
    async [AdminActionKeys.UPDATE_SKYHUB_USER_ROLE](_, user: SkyHubUser) {
      return bff.admin.updateSkyHubUser(user);
    },
    async [AdminActionKeys.FETCH_ALL_USERS]({ commit }) {
      const data = await bff.admin.getAllSkyHubUsers();
      commit('SET_ALL_USERS', data);
    }
  } as ActionTree<AdminState, RootState>;
}

export { createActions, AdminActionKeys };
