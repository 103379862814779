import { MutationTree } from 'vuex';
import { PackageState } from './types';
import { IPackage } from '@/models/packages/Package';
import { IPackageVersion } from '@/models/packages/PackageVersion';

export const mutations: MutationTree<PackageState> = {
  SET_ALL_PACKAGES(state, packs: IPackage[]) {
    state.packs = packs;
  },
  SET_PACKAGE(state, pack: IPackage) {
    state.pack = pack;
  },
  SET_ALL_PACKAGE_VERSIONS(state, versions: IPackageVersion[]) {
    state.versions = versions;
  },
  SET_PACKAGE_VERSION(state, version: IPackageVersion) {
    state.version = version;
  },
  SET_PACKAGE_VERSION_UPLOAD_PROGRESS(state, percentage: number) {
    state.uploadProgressPercentage = percentage;
  }
};
