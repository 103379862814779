import Bff from '@/api/Bff';
import { Module } from 'vuex';
import { RootState } from '../../types';
import { createActions } from './actions';
import { SkyNodeClientState } from './types';
import { getters } from './getters';
import { mutations } from './mutations';
import { ISkyNodeClient } from '@/models/skynode/SkyNodeClient';
import { IPackageVersion } from '@/models/packages/PackageVersion';

export const state: SkyNodeClientState = {
  nodes: [],
  node: {} as ISkyNodeClient,
  packageVersions: [] as IPackageVersion[]
};

export function create(bff: Bff): Module<SkyNodeClientState, RootState> {
  const actions = createActions(bff);
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  } as Module<SkyNodeClientState, RootState>;
}

export const name = 'skyNodeModule';
