import { IPackageVersion } from '@/models/packages/PackageVersion';
import { ISkyNodeClient } from '@/models/skynode/SkyNodeClient';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { SkyNodeClientState } from './types';

export const getters: GetterTree<SkyNodeClientState, RootState> = {
  nodes(state): ISkyNodeClient[] {
    return state.nodes;
  },
  node(state): ISkyNodeClient {
    return state.node;
  },
  packageVersions(state): IPackageVersion[] {
    return state.packageVersions;
  }
};
