import { PackagePlatformType } from './PackagePlatformType';

enum PackageVersionStatus {
  ACTIVE,
  INACTIVE,
  DEPRECATED,
  NONE
}

interface IPackageVersion {
  id: number;
  version: string;
  updatedDate: Date;
  createdDate: Date;
  platform: PackagePlatformType;
  packageLocation: string;
  fileName: string;
  md5: string;
}

interface PackageVersionDTO {
  id: number;
  version: string;
  updatedDate: string;
  createdDate: string;
  platform: PackagePlatformType;
  packageLocation: string;
  md5: string;
}

interface CreatePackageVersionDTO {
  version: string;
  platform: PackagePlatformType;
  md5: string;
  packageLocation: string;
  fileName: string;
}

class PackageVersion {
  public id!: number;
  public version!: string;
  public updatedDate!: Date;
  public createdDate!: Date;
  public platform!: PackagePlatformType;
  public packageLocation!: string;
  public md5!: string;
  public fileName!: 'name';

  public constructor({
    id,
    version,
    updatedDate,
    createdDate,
    platform,
    packageLocation,
    md5,
    fileName
  }: IPackageVersion) {
    Object.assign(this, {
      name,
      id,
      version,
      updatedDate,
      createdDate,
      platform,
      packageLocation,
      md5,
      fileName
    });
  }
}

export {
  PackageVersion,
  IPackageVersion,
  CreatePackageVersionDTO,
  PackageVersionDTO,
  PackageVersionStatus
};
