import firebase from 'firebase';
import { UserRole } from './UserRoles';
import { plainToClass } from 'class-transformer';

interface ISkyHubUser {
  uid: string;
  email: string;
  displayName: string;
  photoUrl: string;
  role: UserRole;
}

class SkyHubUser {
  public uid!: string;
  public email!: string | '';
  public displayName!: string;
  photoUrl = '';
  public role = UserRole.USER;

  static fromAuthClient(user: firebase.User) {
    return plainToClass(SkyHubUser, {
      uid: user.uid,
      email: user.email || '',
      displayName: user.displayName || '',
      photoUrl: user.photoURL || '',
      role: UserRole.USER
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(user: any) {
    // currently the api will return photoUrl as null which causes
    // class-transformer to not apply a default value
    // this explicit check is necessary until the api sanitizes to non-null
    if (user.photoUrl === null) {
      user.photoUrl = undefined;
    }
    return plainToClass(SkyHubUser, user, { exposeDefaultValues: true });
  }
}

export { SkyHubUser, ISkyHubUser };
