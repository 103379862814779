import { IApi, Api } from './Api';
import { AxiosInstance } from 'axios';

class SkyNodeApi extends Api implements IApi {
  public constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }
}

export { SkyNodeApi };
