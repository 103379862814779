import Bff from '@/api/Bff';
import {
  ISkyNodeClient,
  UpdateSkynodeAllowDto
} from '@/models/skynode/SkyNodeClient';
import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { SkyNodeClientState } from './types';
class ActionKeys {
  public static readonly GET_ALL_SKYNODES = 'getAllSkyNodes';
  public static readonly FETCH_BY_ID = 'fetchById';
  public static readonly UPDATE_SKYNODE_ALLOW = 'updateSkynodeAllow';
}

function createActions(bff: Bff): ActionTree<SkyNodeClientState, RootState> {
  return {
    async [ActionKeys.GET_ALL_SKYNODES]({ commit }) {
      const data = await bff.getAllSkyNodes();
      commit('SET_ALL_SKY_NODES', data);
    },
    async [ActionKeys.FETCH_BY_ID](
      { commit },
      id: number
    ): Promise<ISkyNodeClient | undefined> {
      try {
        const data = await bff.getSkyNodeById(id);
        commit('SET_SKYNODE', data);
        if (data) {
          const packageVersions = await bff.getPackageVersionsForPackages(
            data.packages
          );
          commit('SET_PACKAGE_VERSIONS', packageVersions);
        }
        return data;
      } catch (err) {
        commit('SET_SKYNODE', {});
      }
    },
    async [ActionKeys.UPDATE_SKYNODE_ALLOW](
      { commit },
      updateDto: UpdateSkynodeAllowDto
    ): Promise<void> {
      await bff.updateSkynodeAllow(updateDto);
      commit('UPDATE_SKYNODE_ALLOW', updateDto.allow);
    }
  } as ActionTree<SkyNodeClientState, RootState>;
}

export { createActions, ActionKeys };
