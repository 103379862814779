import Bff from '@/api/Bff';
import { IPackage } from '@/models/packages/Package';
import { IPackageVersion } from '@/models/packages/PackageVersion';
import { Module } from 'vuex';
import { RootState } from '../../types';
import { createActions } from './actions';
import { getters as packageGetters } from './getters';
import { mutations as packageMutations } from './mutations';
import { PackageState } from './types';

export const state: PackageState = {
  packs: [],
  pack: {} as IPackage,
  versions: [],
  version: {} as IPackageVersion,
  uploadProgressPercentage: 0
};

export function create(bff: Bff): Module<typeof state, RootState> {
  const actions = createActions(bff);
  const getters = packageGetters;
  const mutations = packageMutations;

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  } as Module<typeof state, RootState>;
}

export const name = 'packageModule';
